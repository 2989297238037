<template>
  <div>
    <div class="banner">
      <img src="../../assets/images/product-banner_11.png" alt="" />
    </div>
    <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div>
    <div class="program-wrap" id="program-wrap">
      <div
        class="program-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        方案概述
      </div>
      <div
        class="program-mes fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        智能化水电站检修服务解决方案，主要是针对常规水电站的日常检测、清理、修缮等业务需求而提供的定制化服务方案，该方案不但可基于先进的水下机器人技术，并通过加装定制化的专业设备，来实现坝体状况动态检测、小障碍物清除、坝体附着物清理、样本取样等多种坝体检测和维护工作，还可以通过水下蛙人，实现对大坝较大障碍物清除、水下修补等特殊工作，以此较全面的满足水电站的检修服务工作
      </div>
      <div class="program-nav">
        <div :class="{ active: active == 1 }" @click="active = 1">
          水下机器人
        </div>
        <div :class="{ active: active == 2 }" @click="active = 2">水下蛙人</div>
      </div>
      <div class="program-content" v-if="active == 1">
        <div class="station-img">
          <img src="../../assets/images/station-img_1.png" alt="" />
        </div>
        <div class="station-content">
          <div class="station-title">水下机器人</div>
          <div class="station-items">
            <div class="station-item">
              <div>
                <img src="../../assets/images/station-icon_1.png" alt="" />
              </div>
              <span>坝体健康检测</span>
            </div>
            <div class="station-item">
              <div>
                <img src="../../assets/images/station-icon_2.png" alt="" />
              </div>
              <span>小障碍物清除</span>
            </div>
            <div class="station-item">
              <div>
                <img src="../../assets/images/station-icon_3.png" alt="" />
              </div>
              <span>坝体附着物清理</span>
            </div>
            <div class="station-item">
              <div>
                <img src="../../assets/images/station-icon_4.png" alt="" />
              </div>
              <span>样本取样</span>
            </div>
          </div>
        </div>
      </div>
      <div class="program-content" v-else>
        <div class="station-img">
          <img src="../../assets/images/station-img_2.png" alt="" />
        </div>
        <div class="station-content">
          <div class="station-title">水下蛙人</div>
          <div class="station-items station-items2">
            <div class="station-item">
              <div>
                <img src="../../assets/images/station-icon_5.png" alt="" />
              </div>
              <span>大障碍物清除</span>
            </div>
            <div class="station-item">
              <div>
                <img src="../../assets/images/station-icon_6.png" alt="" />
              </div>
              <span>水下修补</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";

export default {
  data() {
    return {
      navList: [
        {
          name: "方案概述",
          url: "program-wrap",
        },
      ],
      active: 1,
      animateData: {
        animate: false,
        animate1: false,
      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}
.program-wrap {
  padding: 50px 0 43px;
  background: #fff;
  // position: relative;
  .program-title {
    color: #243159;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
  }
  .program-mes {
    font-size: 16px;
    line-height: 30px;
    color: #63697b;
    width: 995px;
    margin: 24px auto 0;
    text-align: center;
  }
  .program-nav {
    padding: 0 219px 0 244px;
    width: 1180px;
    margin: 70px auto 30px;
    display: flex;
    justify-content: space-between;
    div {
      color: #3d4355;
      font-size: 20px;
      position: relative;
      cursor: pointer;
      padding-bottom: 7px;
      border-bottom: 3px solid transparent;
      transition: border 0.5s ease-in-out;
      &.active {
        font-weight: bold;
        border-bottom: 3px solid #3475fc;
      }
    }
  }
  .program-content {
    width: 1180px;
    height: 518px;
    margin: 44px auto 0;
    display: flex;
    box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
    border-radius: 8px;
    padding: 26px 28px;
  }
  .station-content {
    padding: 36px 0 0;
    flex: 1;
  }
  .station-img {
    width: 807px;
    overflow: hidden;
    img {
      width: 100%;
      transition: all 0.5s ease-in-out;
    }
    img:hover {
      transform: scale(1.1);
    }
  }
  .station-title {
    color: #3d4355;
    font-size: 24px;
    line-height: 31px;
    margin-left: 49px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 24px;
      background: #3475fc;
      left: -17px;
      top: 5px;
    }
  }
  .station-items {
    display: flex;
    flex-wrap: wrap;
    padding-top: 49px;
    margin-left: 20px;
  }
  .station-items2 {
    flex: 1;
    padding-top: 36px;
    .station-item {
      width: 100%;
      div {
        width: 100px;
      }
      &:nth-child(2) {
        margin-bottom: 0;
      }
    }
  }
  .station-item {
    width: 50%;
    text-align: center;
    margin-bottom: 62px;
    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0;
    }
    div {
      width: 75px;
      margin: 0 auto;
      img {
        width: 100%;
      }
      margin-bottom: 15px;
    }
    span {
      color: #7e8394;
      font-size: 16px;
      line-height: 21px;
    }
  }
}
</style>