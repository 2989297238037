<template>
  <div>
    <div class="banner">
      <img src="../../assets/images/product-banner_12.png" alt="" />
    </div>
    <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div>
    <div class="program-wrap" id="program-wrap">
      <div
        class="program-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        方案概述
      </div>
      <div
        class="program-mes fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        变电站设备运维服务解决方案，主要是针对变电站的变压器状态检修和故障预测的业务需求而提供的专业服务方案，该方案基于泛在物联网的移动油色谱试验系统技术，可以实现离线试验数据和油色谱在线监测装置的数据自动耦合分析，并通过离线试验数据和在线装置的交互，制定相应的运维规范和标准接口，提高油色谱在线监测装置的数据准确性，降低油色谱在线监测装置的误报率，并为挖掘油色谱在线监测装置运行状态和监测数据以及变压器运行状态之间的内在规律提供更可靠的数据基础，以此提高油色谱在线监测装置的运行质量
      </div>
      <div class="program-content">
        <div class="substation-top">
          <img
            class="substation-top_img"
            src="../../assets/images/substation-img_6.png"
            alt=""
          />
          <span class="substation-name"
            >移动油色谱试验<br />
            数据平台</span
          >
          <div class="substation-top-block">
            <div class="substation-top_left">
              <img src="../../assets/images/substation-img_1.png" alt="" />
              <span>在线数据</span>
            </div>
            <img
              class="substation-top-arrow_1"
              src="../../assets/images/substation-img_3.png"
              alt=""
            />
            <img
              class="substation-top-card_1"
              src="../../assets/images/substation-img_4.png"
              alt=""
            />
            <img
              class="substation-top_img_1"
              src="../../assets/images/substation-img_5.png"
              alt=""
            />
            <img
              class="substation-top-card_2"
              src="../../assets/images/substation-img_4.png"
              alt=""
            />

            <img
              class="substation-top-arrow_2"
              src="../../assets/images/substation-img_3.png"
              alt=""
            />

            <div class="substation-top_right">
              <img src="../../assets/images/substation-img_2.png" alt="" />
              <span>管理系统</span>
            </div>

            <img
              class="substation-top-arrow_3"
              src="../../assets/images/substation-arrow_1.png"
              alt=""
            />
            <img
              class="substation-top-arrow_4"
              src="../../assets/images/substation-arrow_1.png"
              alt=""
            />
          </div>
        </div>
        <div class="substation-center">
          <div class="substation-center_name">
            基于泛在物联网技术的移动油 色谱试验系统总体架构图
          </div>
          <div class="substation-center_left">
            <div class="substation-center_icon">
              <img src="../../assets/images/substation-icon_1.png" alt="" />
            </div>
            <div class="substation-center_iconName">5G专网</div>
            <div class="substation-center_arrow">
              <img src="../../assets/images/substation-arrow_3.png" alt="" />
            </div>
            <div class="substation-center_icon">
              <img src="../../assets/images/substation-icon_2.png" alt="" />
            </div>
            <div class="substation-center_iconName">一体化移动色谱分析仪</div>
          </div>
          <div class="substation-center_left substation-center_right">
            <div class="substation-center_icon">
              <img src="../../assets/images/substation-icon_3.png" alt="" />
            </div>
            <div class="substation-center_iconName">油色谱在线监测装置</div>
            <div class="substation-center_arrow">
              <img src="../../assets/images/substation-arrow_3.png" alt="" />
            </div>
            <div class="substation-center_icon">
              <img src="../../assets/images/substation-icon_4.png" alt="" />
            </div>
            <div class="substation-center_iconName">变压器</div>
          </div>
        </div>
        <div class="substation-bottom">
          <img src="../../assets/images/substation-arrow_2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";

export default {
  data() {
    return {
      navList: [
        {
          name: "方案概述",
          url: "program-wrap",
        },
      ],
      animateData: {
        animate: false,
        animate1: false,
      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}
.program-wrap {
  padding: 50px 0 153px;
  background: #fff;
  // position: relative;
  .program-title {
    color: #243159;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
  }
  .program-mes {
    width: 995px;
    margin: 22px auto;
    font-size: 16px;
    color: #63697b;
    line-height: 34px;
    text-align: center;
  }
  .program-content {
    width: 1180px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 28px 0;
    align-items: center;
  }
  .substation-top {
    margin: 0 auto;
    position: relative;
    .substation-top_img {
      width: 390px;
    }
    .substation-name {
      position: absolute;
      left: 50%;
      bottom: 103px;
      transform: translate(-50%, 0);
      font-size: 14px;
      font-weight: bold;
      color: #1d5690;
      text-align: center;
    }

    .substation-top-block {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 140px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .substation-top_img_1 {
      width: 106px;
      height: 148px;
      margin: 0 49px;
    }
    .substation-top_left {
      width: 96px;
      height: 138px;
      position: relative;
      img {
        width: 100%;
      }
      span {
        position: absolute;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, 0);
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
        color: #4f5978;
        padding: 5px 12px 7px;
        background: #ffffff;
        box-shadow: 0px 2px 9px 4px rgba(110, 138, 225, 0.12);
        &::before {
          content: "";
          width: 0;
          height: 0;
          border-width: 0 10px 10px;
          border-style: solid;
          border-color: transparent transparent#fff;
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
    .substation-top_right {
      width: 96px;
      height: 138px;
      position: relative;
      img {
        width: 100%;
      }
      span {
        position: absolute;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, 0);
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
        color: #4f5978;
        padding: 5px 12px 7px;
        background: #ffffff;
        box-shadow: 0px 2px 9px 4px rgba(110, 138, 225, 0.12);
        &::before {
          content: "";
          width: 0;
          height: 0;
          border-width: 0 10px 10px;
          border-style: solid;
          border-color: transparent transparent#fff;
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
    .substation-top-arrow_1 {
      width: 78px;
      height: 19px;
      margin: 0 20px 0 14px;
    }
    .substation-top-arrow_2 {
      width: 78px;
      height: 19px;
      margin: 0 18px 0 10px;
    }
    .substation-top-arrow_3 {
      position: absolute;
      width: 119px;
      top: 240px;
      left: 78px;
    }
    .substation-top-arrow_4 {
      position: absolute;
      width: 119px;
      top: 240px;
      right: 80px;
      transform: rotate(60deg);
    }
  }
  .substation-center {
    width: 443px;
    height: 156px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 5px rgba(4, 14, 52, 0.06);
    border-radius: 22px;
    margin: 86px 0 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .substation-center_name {
      text-align: center;
      width: 312px;
      height: 80px;
      font-size: 24px;
      font-family: MicrosoftYaHei;
      color: #243159;
      line-height: 40px;
    }
  }
  .substation-center_left {
    position: absolute;
    left: -240px;
    top: -90px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .substation-center_right {
    left: unset;
    right: -240px;
  }
  .substation-center_icon {
    width: 130px;
    height: 130px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 5px rgba(107, 107, 107, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      width: 68px;
    }
  }
  .substation-center_iconName {
    font-size: 14px;
    color: #243159;
    padding: 7px 11px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #bdc5e2;
    margin-top: 18px;
  }
  .substation-center_arrow {
    margin: 10px 0;
  }
}
</style>